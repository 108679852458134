import { useEffect, useState } from 'react';
import { endpoints } from '../config';
import { FormData, OrderDataA, OrderDataB, OrderDataC } from '../ts/interfaces';
import { FormType } from '../ts/types';

const initialFormData: FormData = {
  id: '',
  dob: '',
  ssnLast4: '',
  optAddress: '',
  billingAddress: '',
  shippingAddress: '',
  imageError: 'NO',
  imageErrorDetail: '',
  driverLicense: undefined,
};

/**
This service is a ***custom-hook*** because it encapsulates the component lifecycle logic.
Getting the necesary data to render the app.
*/
export const useGetOrderData = () => {
  const [data, setData] = useState<OrderDataA | OrderDataB | OrderDataC>(
    {} as OrderDataA | OrderDataB | OrderDataC
  );
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState(initialFormData);
  const [userFullname, setUserFullname] = useState(null);

  useEffect(() => {
    const id = new URLSearchParams(window.location.search).get('id');
    const formType = new URLSearchParams(window.location.search).get(
      'form-type'
    ) as FormType | null;

    // this is for using the application on local environment (forms)
    if (id === 'TEST_ID' && (formType === 'A' || formType === 'B' || formType === 'C')) {
      formType === 'A'
        ? setData({
          ok: true,
          orderId: id,
          optAddresses: [
            '123 Fake Address 1',
            '123 Fake Address 2',
            '123 Fake Address 3',
            '123 Fake Address 4',
          ],
          formType: 'A',
        })
        : formType === 'B'
          ? setData({
            ok: true,
            orderId: id,
            formType: 'B',
          })
          : setData({
            ok: true,
            orderId: id,
            formType: 'C',
          });

      setIsLoading(false);

      setFormState((current) => {
        return { ...current, id };
      });

      // this is the actual code to execute in prod for any of the main forms
    } else if (id !== null && (formType === 'A' || formType === 'B' || formType === 'C')) {
      setFormState((current) => {
        return { ...current, id };
      });

      const getOrderDataA = async () => {
        try {
          const res = await fetch(endpoints.getOrderDataA, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
          });
          const data = await res.json();

          setData({
            ok: data?.OK ?? false,
            orderId: id,
            optAddresses: data?.addresses ?? [],
            formType: 'A',
          });

          const fetchUser = await fetch(endpoints.getClientName, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id }),
          });

          const username = await fetchUser.json();
          if (username.OK) setUserFullname(username.fullName);

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      };

      const getOrderDataB = async () => {
        setData({
          ok: true,
          orderId: id,
          formType: 'B',
        });
        const fetchUser = await fetch(endpoints.getClientName, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id }),
        });
        const username = await fetchUser.json();
        if (username.OK) setUserFullname(username.fullName);
        setIsLoading(false);
      };

      const getOrderDataC = () => {
        setData({
          ok: true,
          orderId: id,
          formType: 'C',
        });
        setIsLoading(false);
      };

      formType === 'A' ? getOrderDataA() : formType === 'B' ? getOrderDataB() : getOrderDataC();
    } else if (id !== null && formType === 'F') {
      const answer = new URLSearchParams(window.location.search).get('answer');
      if (answer === 'YES' || answer === 'NO') {
        const postFinancingCustomerConfirmation = async () => {
          const body = JSON.stringify({
            id,
            answer,
          });
          try {
            await fetch(endpoints.postFinancingCustomerConfirmation, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body,
            });
            setData({
              ok: true,
              orderId: id,
              formType: 'F',
            });
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoading(false);
          }
        };
        void postFinancingCustomerConfirmation();
      } else {
        setIsLoading(false);
      }
    } else {
      setData({
        ok: false,
        orderId: '',
        optAddresses: [],
        formType: 'A',
      });
      setIsLoading(false);
    }
  }, [setData, setIsLoading]);

  return { data, isLoading, formState, setFormState, userFullname };
};

interface PostFormData {
  data: FormData;
  formType: FormType;
}

/**
  This service is a ***custom-hook*** because it is automatically  when the component is mounted
**/

export const usePostFormData = ({ data, formType }: PostFormData) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    if (data.id === 'TEST_ID') {
      const postFormTest = async () => {
        try {
          console.log('data', data);
          setIsLoading(false);
        } catch (err) {
          console.error(err);
        }
      };

      postFormTest();
    } else {
      const postFormAData = async () => {
        try {
          setIsLoading(true);
          const response = await fetch(endpoints.postFormAData, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();

          if (result.result === 'Approved') {
            setIsApproved(true);
            setIsLoading(false);
          } else {
            setIsApproved(false);
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
        }
      };

      const postFormBData = async () => {
        try {
          setIsLoading(true);
          const response = await fetch(endpoints.postFormBData, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          const result = await response.json();

          if (result.result === 'Approved') {
            setIsApproved(true);
            setIsLoading(false);
          } else {
            setIsApproved(false);
            setIsLoading(false);
          }
        } catch (err) {
          console.error(err);
        }
      };

      formType === 'A' ? postFormAData() : postFormBData();
    }
  }, [data, formType]);

  return { isLoading, isApproved };
};
