import { useEffect, useState } from "react";

const useIsMobilePortrait = () => {
  const [isP, setIsP] = useState(false);

  const changeOrientation = () => {
    setIsP((cur) => !cur);
  };

  useEffect(() => {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    const isAndroid = navigator.userAgent.includes("Android");
    const isIPhone = navigator.userAgent.includes("iPhone");
    setIsP(portrait && (isAndroid || isIPhone));

    window.addEventListener("orientationchange", changeOrientation);
    return () =>
      window.removeEventListener("orientationchange", changeOrientation);
  }, []);

  return isP;
};

export default useIsMobilePortrait;
