import { Box, Fade, Typography } from "@mui/material";

import { usePostFormData } from "../../services";
import { FormAProps } from "../../ts/interfaces";
import { FormType } from "../../ts/types";
import { useTranslation } from "react-i18next";

const SuccessPage = ({
  formState,
  formType,
}: Pick<FormAProps, "formState"> & { formType: FormType }) => {
  delete formState.driverLicense;

  const [t] = useTranslation("global");
  const { isLoading, isApproved } = usePostFormData({
    data: formState,
    formType,
  });

  console.log("isApproved", isApproved)

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="center"
      alignItems="center"
      width="100%"
      border="1px solid"
      borderColor="secondary.main"
      borderRadius={2}
    >
      {isLoading ? (
        <Typography>{t("formA.successPage.loading")}</Typography>
      ) : isApproved ? (
        <>
          <Fade in timeout={750}>
            <Typography variant="h3" textAlign="center" color="primary" px="2px">
            {t("formA.successPage.approved.1")}
            </Typography>
          </Fade>
          <Fade in timeout={1750}>
            <Typography align="center" color="primary" px="8px">
            {t("formA.successPage.approved.2")}
            </Typography>
          </Fade>
          <Fade in timeout={2750}>
            <Typography align="center" color="primary" px="2px">
            {t("formA.successPage.approved.3")}
            </Typography>
          </Fade>
          <Fade in timeout={3750}>
            <Typography align="center" color="primary">
              Raymour & Flanigan
            </Typography>
          </Fade>
        </>
      ) : (
        <>
          <Fade in timeout={750}>
            <Typography fontSize="1.2rem" textAlign="center" color="primary" px="8px">
            {t("formA.successPage.denied.1")}
            </Typography>
          </Fade>
          <Fade in timeout={1750}>
            <Typography fontSize="1.2rem" align="center" color="primary">
              Raymour & Flanigan
            </Typography>
          </Fade>
        </>
      )}
    </Box>
  );
};

export default SuccessPage;
