import { AppBar, Box, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';

import logotype from './assets/raymour_logo_purple_desktop.svg';
import FormA from './components/FormA';
import FormB from './components/FormB';
import FormC from './components/FormC';
import FinancingCustomerConfirmation from './components/FinancingCustomerConfirmation';
import StepperA from './components/StepperA';
import StepperB from './components/StepperB';
import { useGetOrderData } from './services';
import { OrderDataA, OrderDataB, OrderDataC } from './ts/interfaces';
import LanguageSwitcher from './components/LanguageSwitcher';

const App = () => {
	const { data: orderData, isLoading, formState, setFormState, userFullname } = useGetOrderData();
	const [activeStep, setActiveStep] = useState(0);

	return (
		<Box bgcolor="secondary.light">
			<AppBar variant="elevation" color="primary" sx={{ position: 'relative' }}>
				<Box component="img" src={logotype} alt="Raymour & Flanigan" height="70px" p={2} />
				<LanguageSwitcher />
			</AppBar>
			<Box height="70px" />
			<Box
				position="absolute"
				top="70px"
				bottom={0}
				width="100vw"
				display="flex"
				flexDirection="column"
				bgcolor="secondary.light"
			>
				{isLoading ? (
					<Box
						display="flex"
						flexDirection="column"
						gap={3}
						alignItems="center"
						flex="1 1 0"
						width="100%"
						p={2}
					>
						<CircularProgress />
						Please wait a second
					</Box>
				) : orderData.ok && orderData.formType === 'A' ? (
					<FormA
						activeStep={activeStep}
						orderData={orderData as OrderDataA}
						formState={formState}
						setFormState={setFormState}
						userFullname={userFullname}
					/>
				) : orderData.ok && orderData.formType === 'B' ? (
					<FormB
						activeStep={activeStep}
						orderData={orderData as OrderDataB}
						formState={formState}
						setFormState={setFormState}
						userFullname={userFullname}
					/>
				) : orderData.ok && orderData.formType === 'C' ? (
					<FormC
						activeStep={activeStep}
						orderData={orderData as OrderDataC}
						formState={formState}
						setFormState={setFormState}
					/>
				) : orderData.ok && orderData.formType === 'F' ? (
					<FinancingCustomerConfirmation />
				) : (
					<Box
						display="flex"
						flexDirection="column"
						gap={3}
						alignItems="center"
						flex="1 1 0"
						width="100%"
						p={2}
					>
						<Typography color="error" variant="h5">
							Unable to continue
						</Typography>
					</Box>
				)}
				{orderData.ok && orderData.formType === 'A' ? (
					<StepperA formState={formState} activeStep={activeStep} setActiveStep={setActiveStep} />
				) : orderData.ok && orderData.formType === 'B' ? (
					<StepperB formState={formState} activeStep={activeStep} setActiveStep={setActiveStep} />
				) : null}
			</Box>
		</Box>
	);
};

export default App;
