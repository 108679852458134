export const loadAsyncScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

export const googleMapsAPI = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBgNRug8VZBZq6uN7TWDLqtD61cO0e3qHY&libraries=places`

export const extractAddress = (place: any) => {
  const address = {
    street: "",
    city: "",
    state: "",
    zip: "",
  };

  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address.street = `${component.long_name} ${address.street}`;
        break;
      }

      case "route": {
        address.street += component.short_name;
        break;
      }

      case "locality":
        address.city = component.long_name;
        break;

      case "administrative_area_level_1": {
        address.state = component.short_name;
        break;
      }

      case "postal_code": {
        address.zip = component.long_name;
        break;
      }

      default:
        break;
    }
  }

  return `${address.street}, ${address.city}, ${address.state}, ${address.zip}`
}

export const capitalizeFullname = (fullName: string | null) => {
  if (!fullName) return null;

  const names = fullName.split(" ");

  const firstName = names[0].charAt(0).toUpperCase() + names[0].slice(1).toLowerCase();
  const lastName = names[names.length - 1].charAt(0).toUpperCase() + names[names.length - 1].slice(1).toLowerCase();
  return `${firstName} ${lastName}`;
};