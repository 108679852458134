import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";

import { FormAProps } from "../../ts/interfaces";
import { useTranslation } from "react-i18next";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

const Page1 = ({
  orderData,
  formState,
  setFormState,
}: Pick<FormAProps, "orderData" | "formState" | "setFormState">) => {
  const [dob, setDob] = useState<Dayjs | null>(null);
  const [t] = useTranslation("global");
  const theme = useTheme();

  const maxDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 18)
  );
  const formatedMaxDate = [
    maxDate.getFullYear(),
    (maxDate.getMonth() + 1).toString().padStart(2, "0"),
    maxDate.getDate().toString().padStart(2, "0"),
  ].join("-");
  const minDate = new Date(new Date().setFullYear(1930));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    if (name === "ssnLast4" && value.length > 4) {
      return;
    }

    setFormState((current) => {
      return { ...current, [name]: value };
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <DesktopDatePicker
          sx={{ width: "100%" }}
          maxDate={dayjs(formatedMaxDate)}
          label={t("formA.page1.birth")}
          value={dob}
          onChange={(newValue) => {
            if (!newValue) return;

            setDob(newValue);
            const selectedDate = newValue.toDate();
            console.log("selectedDate", selectedDate)

            const formatedDate = `${newValue?.year()}-${
              (newValue?.month() + 1).toString().padStart(2, "0")
            }-${newValue?.date().toString().padStart(2, "0")}`;

            if (formatedDate.length < 8 || formatedDate.includes("NaN") || selectedDate > maxDate || selectedDate < minDate || selectedDate.toString() === "Invalid Date") {
              setFormState((current) => {
                console.log("current1", {
                  ...current,
                  dob: "",
                })
                return {
                  ...current,
                  dob: "",
                };
              });
            } else {
              setFormState((current) => {
                console.log("current2", {
                  ...current,
                  dob: formatedDate,
                })
                return {
                  ...current,
                  dob: formatedDate,
                };
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("formA.page1.ssn")}
          placeholder="***-**-1234"
          name="ssnLast4"
          value={formState.ssnLast4}
          onChange={handleChange}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          sx={{
            border: "1px solid #bbb",
            borderRadius: 1,
            p: 1.5,
            "&:hover": {
              borderColor: "black",
            },
            "&:focus-within": {
              borderColor: "primary.main",
              boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
            },
          }}
        >
          <FormLabel
            sx={{
              px: "6px",
              top: -9,
              zIndex: 1,
              fontSize: 12,
              position: "absolute",
              backgroundColor: "secondary.light",
              userSelect: "none",
            }}
          >
            {t("formA.page1.prevAddress")}
          </FormLabel>
          <RadioGroup
            name="optAddress"
            value={formState.optAddress}
            onChange={handleChange}
          >
            {orderData.optAddresses &&
              orderData.optAddresses.map((opt) => (
                <FormControlLabel
                  key={opt}
                  value={opt}
                  control={<Radio />}
                  label={opt}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default Page1;