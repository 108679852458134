import { useState } from 'react';

import { FormBProps } from '../ts/interfaces';

const AZURE_VISION_ENDPOINT_URL =
	'https://idtojson.azurewebsites.net/api/ID_TO_JSON?code=3yQ44ZfELBISvxOEDZqFsTlEv3WC85CvGTfq4CsDD4UwAzFugmuX_A==';
const PROMPT =
	'Analyze the provided image of an identification document and extract the following fields: full address, first name, last name, date of birth, and identification number. Address should be formatted as "{AddressLine1} {City} {State}", no commas, no periods, all caps. If a field is unavailable, leave it as null. Ensure accuracy in identifying and transcribing the information. Pay special attention to the correct spelling of names, correct formatting of the date of birth, and accurate transcription of the identification number and address. After extraction, please recheck the accuracy of the extracted information. Output only the extracted data as a plain JSON object without any additional formatting or text. The JSON structure should be: {"fullAddress":"the extracted full address","firstName":"the extracted first name","lastName":"the extracted last name","dob":"the extracted date of birth in the format of yyyy-MM-dd","dlNumber":"the extracted identification number"}';

const extractJsonFromLicense = async (image: string) => {
	try {
		const res = await fetch(AZURE_VISION_ENDPOINT_URL, {
			method: 'POST',
			body: JSON.stringify({
				image,
				prompt: PROMPT,
			}),
		});
		const data = await res.json();
		return data;
	} catch (error) {
		console.error(error);
	}
};

const useExtractFieldsFromPicture = ({ setFormState }: Pick<FormBProps, 'setFormState'>) => {
	const [procesingPhoto, setProcesingPhoto] = useState(false);
	const [parseError, setParseError] = useState({ error: false, errorCount: 0 });

	const extractFieldsFromPicture = async (photoUrl: string) => {
		setProcesingPhoto(true);
		try {
			const jsonLicense = (await extractJsonFromLicense(photoUrl)) as {
				fullAddress: string | null;
				firstName: string | null;
				lastName: string | null;
				dob: string | null;
				dlNumber: string | null;
			};

			const missingField =
				jsonLicense.fullAddress === null ||
				jsonLicense.firstName === null ||
				jsonLicense.lastName === null ||
				jsonLicense.dob === null ||
				jsonLicense.dlNumber === null;

			const shouldIgnoreMissingFields = parseError.errorCount === 1;

			if (missingField) {
				if (shouldIgnoreMissingFields) {
					const imageErrorDetail = `Full Address: ${
						jsonLicense.fullAddress || 'undefined'
					} || First Name: ${jsonLicense.firstName || 'undefined'} || Last Name: ${
						jsonLicense.lastName || 'undefined'
					} || DOB: ${jsonLicense.dob || 'undefined'} || DL Number: ${
						jsonLicense.dlNumber || 'undefined'
					}`;
					setParseError({ error: false, errorCount: 0 });
					setFormState((prev) => ({
						...prev,
						imageError: 'YES',
						imageErrorDetail,
						driverLicense: {
							fullAddress: '',
							firstName: '',
							lastName: '',
							dob: '',
							dlNumber: '',
							base64: photoUrl.replace('data:image/jpeg;base64,', ''),
						},
					}));
				} else {
					setParseError((prev) => ({ error: true, errorCount: prev.errorCount + 1 }));
				}
			} else {
				setFormState((prev) => ({
					...prev,
					imageError: 'NO',
					imageErrorDetail: '',
					driverLicense: {
						fullAddress: jsonLicense.fullAddress!,
						firstName: jsonLicense.firstName!,
						lastName: jsonLicense.lastName!,
						dob: jsonLicense.dob!,
						dlNumber: jsonLicense.dlNumber!,
						base64: photoUrl.replace('data:image/jpeg;base64,', ''),
					},
				}));
			}
		} catch (err) {
			setParseError((prev) => ({ error: true, errorCount: prev.errorCount + 1 }));
			console.error(err);
		} finally {
			setProcesingPhoto(false);
		}
	};

	const clearData = () => {
		setFormState((prev) => ({ ...prev, driverLicense: undefined }));
		setParseError((prev) => ({ ...prev, error: false }));
	};

	return { extractFieldsFromPicture, clearData, parseError, procesingPhoto };
};

export default useExtractFieldsFromPicture;
