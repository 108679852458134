import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import { useState, useCallback, useRef, useEffect } from 'react';
import { FormBProps } from '../../ts/interfaces';
import { extractAddress, googleMapsAPI, loadAsyncScript } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 16));
const minDate = new Date(new Date().setFullYear(1930));
const formatedMaxDate = [
	maxDate.getFullYear(),
	(maxDate.getMonth() + 1).toString().padStart(2, '0'),
	maxDate.getDate().toString().padStart(2, '0'),
].join('-');

const Page1 = ({ formState, setFormState }: Pick<FormBProps, 'formState' | 'setFormState'>) => {
	const [dob, setDob] = useState<Dayjs | null>(null);
	const [sameAddr, setSameAddr] = useState(false);
	const [t] = useTranslation('global');
	const billingRef = useRef<HTMLInputElement | null>(null);
	const shippingRef = useRef<HTMLInputElement | null>(null);

	const handleChangeBilling = (autocomplete: any) => {
		const place = autocomplete.getPlace();
		const address = extractAddress(place);

		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
					shippingAddress: address,
				};
			});
		} else {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
				};
			});
		}
	};

	const handleChangeShipping = (autocomplete: any) => {
		const place = autocomplete.getPlace();
		const address = extractAddress(place);

		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
					shippingAddress: address,
				};
			});
		} else {
			setFormState((current) => {
				return {
					...current,
					shippingAddress: address,
				};
			});
		}
	};

	const initMapScript = () => {
		if (window.google) {
			return Promise.resolve();
		}
		const src = googleMapsAPI;
		return loadAsyncScript(src);
	};

	const initAutocomplete = useCallback(() => {
		if (!billingRef.current || !shippingRef.current) return;

		const componentRestrictions = { country: 'us' };
		const types = ['address'];

		const billingAutocomplete = new window.google.maps.places.Autocomplete(billingRef.current, {
			componentRestrictions,
			types,
		});
		billingAutocomplete.setFields(['address_component', 'geometry']);
		billingAutocomplete.addListener('place_changed', () =>
			handleChangeBilling(billingAutocomplete)
		);

		const shippingAutocomplete = new window.google.maps.places.Autocomplete(shippingRef.current, {
			componentRestrictions,
			types,
		});
		shippingAutocomplete.setFields(['address_component', 'formatted_address']);
		shippingAutocomplete.addListener('place_changed', () =>
			handleChangeShipping(shippingAutocomplete)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sameAddr]);

	useEffect(() => {
		initMapScript().then(() => initAutocomplete());
	}, [initAutocomplete]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;

		if (name === 'ssnLast4' && value.length > 4) {
			return;
		}

		if (sameAddr && name !== 'dob' && name !== 'ssnLast4') {
			setFormState((current) => {
				return { ...current, billingAddress: value, shippingAddress: value };
			});
		} else {
			setFormState((current) => {
				return { ...current, [name]: value };
			});
		}
	};

	const handleSameAddress = () => {
		setSameAddr((prevState) => !prevState);
		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					shippingAddress: '',
				};
			});
		} else {
			setFormState((current) => {
				if (current.billingAddress === '') return current;

				return {
					...current,
					shippingAddress: current.billingAddress,
				};
			});
		}
	};

	return (
		<>
			<Grid item xs={12}>
				<DesktopDatePicker
					sx={{ width: '100%' }}
					maxDate={dayjs(formatedMaxDate)}
					label={t('formB.page1.birth')}
					value={dob}
					onChange={(newValue) => {
						if (!newValue) return;

						setDob(newValue);
						const selectedDate = newValue.toDate();
						console.log('selectedDate', selectedDate);

						const formatedDate = `${newValue?.year()}-${(newValue?.month() + 1)
							.toString()
							.padStart(2, '0')}-${newValue?.date().toString().padStart(2, '0')}`;

						if (
							formatedDate.length < 8 ||
							formatedDate.includes('NaN') ||
							selectedDate > maxDate ||
							selectedDate < minDate ||
							selectedDate.toString() === 'Invalid Date'
						) {
							setFormState((current) => {
								console.log('current1', {
									...current,
									dob: '',
								});
								return {
									...current,
									dob: '',
								};
							});
						} else {
							setFormState((current) => {
								console.log('current2', {
									...current,
									dob: formatedDate,
								});
								return {
									...current,
									dob: formatedDate,
								};
							});
						}
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					label={t('formB.page1.ssn')}
					placeholder="***-**-1234"
					name="ssnLast4"
					value={formState.ssnLast4}
					onChange={handleChange}
					type="number"
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography marginBottom="1rem" marginLeft="0.5rem" color="grey">
					{t('formB.page1.example')}
				</Typography>
				<TextField
					fullWidth
					label={t('formB.page1.billing')}
					placeholder="123 Main St, San Francisco, CA, 94107"
					name="billingAddress"
					value={formState.billingAddress}
					onChange={handleChange}
					inputRef={billingRef}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					label={t('formB.page1.shipping')}
					placeholder="123 Main St, San Francisco, CA, 94107"
					name="shippingAddress"
					value={formState.shippingAddress}
					onChange={handleChange}
					inputRef={shippingRef}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					value={sameAddr}
					onChange={handleSameAddress}
					control={<Checkbox />}
					label={t('formB.page1.checkbox')}
					checked={sameAddr}
				/>
			</Grid>
		</>
	);
};

export default Page1;
