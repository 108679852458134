export const endpoints = {
	getOrderDataA:
		'https://prod-142.westus.logic.azure.com:443/workflows/60cba75233a14056b8c2618e4291dc4b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=PFmI1Ul3HRmfApJRGFc4K4zTH3sJeU3gsbpUCK92A9w',
	postFormAData:
		'https://prod-21.westus.logic.azure.com:443/workflows/bae9cb6ecb2d4f419f501ed91c46f820/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=l2SyeoksmLgaucMypDcPsUTnsBxN7dtYjV7A8fOc63o',
	postFormBData:
		'https://prod-165.westus.logic.azure.com:443/workflows/36fc64c2f3bc4f2ca976998f6ac89e79/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=13qJCbawNEmpxji2g5jl-bM6uVtKNZ1JnoUCreZRChc',
	postAnalyzeImage: 'https://rf-formrecog-devtest.cognitiveservices.azure.com/',
	postFormCData:
		'https://prod-104.westus.logic.azure.com/workflows/3b080273c209492a98bb555493aa1c2b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=YOXDhsl4puyMOxtcyZzAlKWQCx9QrR08CG7Mr02u06c',
	getClientName:
		'https://prod-12.westus.logic.azure.com:443/workflows/cfc89f3890f04f768542c857a23e1e7f/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=o4m4ru7b7VO5kyG0V6WxOM66Pb-d34rKiYULVm37KYg',
	postFinancingCustomerConfirmation:
		'https://prod-128.westus.logic.azure.com:443/workflows/6cb823856cdb45e9834a5e7d52517023/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bS605u_SuXfWOAH1Ph7H5-rFYmcOQiB18nB7dLI6-x8',
};

export const AZ_RECOG_KEY = process.env.REACT_APP_AZ_RECOG_KEY ?? '';
if (!AZ_RECOG_KEY) console.error('AZ_RECOG_KEY env var was not provided');

export const modelID = 'prebuilt-idDocument';
