import { Box, Fade, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

const FinancingCustomerConfirmation = () => {
	const [t] = useTranslation('global');

	return (
		<Box
			m="20px"
			display="flex"
			alignItems="center"
			justifyContent="center"
			border="2px solid gainsboro"
			borderRadius="5px"
			height="100%"
		>
			<Fade in timeout={1000}>
				<Typography color="primary" textAlign="center" fontSize="18px">
					<Box component={'span'} display="block" mb="10px">
						{t('financingCustomerConfirmationSuccess.lineA')} <br />
						{t('financingCustomerConfirmationSuccess.lineB')}
					</Box>
					{t('financingCustomerConfirmationSuccess.lineC')}
				</Typography>
			</Fade>
		</Box>
	);
};

export default FinancingCustomerConfirmation;
