import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const LanguageSwitcher = () => {
    const [, i18n] = useTranslation("global")
    const currentLanguage = i18n.language
    const bgEn = currentLanguage === "en" ? "#a6a6a6" : "#fff"
    const bgEs = currentLanguage === "es" ? "#a6a6a6" : "#fff"

  return (
    <Box position="absolute" right="1rem" top="1.5rem" display="flex" gap="0.2rem">
        <Button onClick={() => i18n.changeLanguage("en")} sx={{color: "black", backgroundColor: bgEn, width: "35px", height: "20px", minWidth: "35px", ":hover": {background: "#f0f0f0"}}}>EN</Button>
        <Button onClick={() => i18n.changeLanguage("es")} sx={{color: "black", backgroundColor: bgEs, width: "35px", height: "20px", minWidth: "35px", ":hover": {background: "#f0f0f0"}}}>ES</Button>
    </Box>
  )
}

export default LanguageSwitcher