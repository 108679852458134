import { Box, Grid, Typography } from "@mui/material";

import { FormBProps } from "../../ts/interfaces";
import Page1 from "./Page1";
import Page2 from "./Page2";
import SuccessPage from "./successPage";
import { useTranslation } from "react-i18next";
import { capitalizeFullname } from "../../utils/utils";

const Form = ({
  activeStep,
  orderData,
  formState,
  userFullname,
  setFormState,
}: FormBProps) => {
  const [t] = useTranslation("global");

  return (
    <Box display="flex" flex="1 1 0" width="100%" p={2}>
      <Grid container>
        {(activeStep === 0 || activeStep === 1) && (
          <>
            <Grid item xs={12} display="flex" gap={1} justifyContent="center">
              <Typography color="secondary.dark">{t("formB.order")}</Typography>
              <Typography color="info.main">#{orderData.orderId}</Typography>
            </Grid>
            {activeStep === 0 && (
              <Grid item xs={12}>
                <Typography align="center" variant="subtitle2">
                  {t("formB.heading1")}
                  {userFullname && capitalizeFullname(userFullname)}
                </Typography>
                <Typography align="center" variant="subtitle2">
                  {t("formB.heading2")}
                </Typography>
              </Grid>
            )}
          </>
        )}
        {activeStep === 0 && (
          <Page1 formState={formState} setFormState={setFormState} />
        )}
        {activeStep === 1 && (
          <Page2 formState={formState} setFormState={setFormState} />
        )}
        {activeStep === 2 && <SuccessPage formState={formState} formType="B" />}
      </Grid>
    </Box>
  );
};

export default Form;
