import { Box, Fade, Typography } from "@mui/material";

import { usePostFormData } from "../../services";
import { FormBProps } from "../../ts/interfaces";
import { FormType } from "../../ts/types";
import { useTranslation } from "react-i18next";

const SuccessPage = ({
  formState,
  formType,
}: Pick<FormBProps, "formState"> & { formType: FormType }) => {
  delete formState.optAddress;

  const { isLoading, isApproved } = usePostFormData({
    data: formState,
    formType,
  });
  const [t] = useTranslation("global");

  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      justifyContent='center'
      alignItems='center'
      width='100%'
      border='1px solid'
      borderColor='secondary.main'
      borderRadius={2}
    >
      {isLoading ? (
        <Typography>{t("formB.successPage.loading")}</Typography>
      ) : isApproved ? (
        <>
          <Fade in timeout={750}>
            <Typography variant="h3" textAlign="center" color="primary">
            {t("formB.successPage.success1")}
            </Typography>
          </Fade>
          <Fade in timeout={1750}>
            <Typography align="center" color="primary" px="2rem">
              {t("formB.successPage.success2")}
            </Typography>
          </Fade>
        </>
      ) : (
        <Typography align="center" color="primary" px="2rem">
          {t("formB.successPage.error")}
        </Typography>
      )}
    </Box>
  );
};

export default SuccessPage;
