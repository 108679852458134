import { Box, Button, Fade, Grid, Typography } from "@mui/material";

import { FormCProps } from "../../ts/interfaces";
import { endpoints } from "../../config";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Form = ({ orderData }: FormCProps) => {
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [showEndScreen, setShowEndScreen] = useState(false);
  const [t] = useTranslation("global");

  const handlePostFormCData = async () => {
    setVerificationCompleted(true);
    try {
      await fetch(endpoints.postFormCData, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: orderData.orderId }),
      });
      setShowEndScreen(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (showEndScreen) {
    return (
      <Box
        display="flex"
        width="100%"
        height="100%"
        p={2}
        alignSelf="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          justifyContent="center"
          alignItems="center"
          width="100%"
          border="1px solid"
          borderColor="secondary.main"
          borderRadius={2}
        >
          <Fade in timeout={750}>
            <Typography variant="h3" textAlign="center" color="primary">
              {t("formC.success1")}
            </Typography>
          </Fade>
          <Fade in timeout={1750}>
            <Typography align="center" color="primary" px="2rem">
            {t("formC.success2")}
            </Typography>
          </Fade>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      p={2}
      alignSelf="center"
      justifyContent="center"
    >
      <Grid container width={{ xs: "90%", sm: "70%", md: "50%", xl: "30%" }}>
        <Grid item xs={12} display="flex" gap={1} justifyContent="center">
          <Typography color="secondary.dark">{t("formC.order")}</Typography>
          <Typography color="info.main">#{orderData.orderId}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="subtitle2"
            fontWeight="bold"
            mb={2}
          >
            {t("formC.text1")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            align="center"
            variant="subtitle2"
            fontWeight="bold"
            mb={2}
            fontSize="12px"
          >
            {t("formC.text2")}
          </Typography>
          <Typography
            align="center"
            variant="subtitle2"
            fontWeight="bold"
            mb={2}
          >
            {t("formC.text3")} <a href="tel:888-925-0913" onClick={handlePostFormCData}>888-925-0913</a>.
          </Typography>
          <Typography align="center" variant="subtitle2" fontWeight="bold">
            {t("formC.text4")}
          </Typography>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" color="black">
          <Button
            sx={{
              width: { xs: "200px", sm: "300px" },
              height: "60px",
              fontWeight: "bold",
            }}
            color="primary"
            variant="contained"
            onClick={handlePostFormCData}
            disabled={verificationCompleted}
          >
            {t("formC.button")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
