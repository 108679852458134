import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import {
  Cameraswitch,
  SwapHoriz,
  CheckCircleOutline,
} from "@mui/icons-material";
import { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import styles from "./webcamStyles.module.css";
import useParseImageData from "../../services/useExtractFieldsFromPicture";

import { FormBProps } from "../../ts/interfaces";
import useIsMobilePortrait from "../../config/useIsMobilePortrait";
import { useTranslation } from "react-i18next";

const Page2 = ({
  formState,
  setFormState,
}: Pick<FormBProps, "formState" | "setFormState">) => {
  const [camOn, setCamOn] = useState(false);
  const [mirrored, setMirrored] = useState(false);
  const [shouldTakePhoto, setShouldTakePhoto] = useState(false);
  const [loadingCam, setLoadingCam] = useState(false);
  const [devices, setDevices] = useState<string[]>([]);
  const [deviceId, setDeviceId] = useState<string | undefined>(undefined);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);
  const [t] = useTranslation("global");

  const isPortrait = useIsMobilePortrait();

  const { extractFieldsFromPicture, clearData, parseError, procesingPhoto } =
    useParseImageData({
      setFormState,
    });

  const webCam = useRef<Webcam>(null);

  // load video devices
  useEffect(() => {
    const handleDevices = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const deviceIds = mediaDevices
          .filter(({ kind }) => kind === "videoinput")
          .map((device) => device.deviceId);
        setDevices(deviceIds);
      } catch (err) {
        // If permission is not granted, prevent form from moving to the next step
        console.error(err);
        return;
      }
    };

    if (devices.length < 2) {
      handleDevices();
    }
    // eslint-disable-next-line
  }, [loadingCam]);

  // Take the photo
  useEffect(() => {
    if (webCam.current && shouldTakePhoto && !mirrored) {
      const screenshot = webCam.current.getScreenshot();

      if (screenshot) {
        setPhotoUrl(screenshot);
      }
      setShouldTakePhoto(false);
    }
  }, [shouldTakePhoto, mirrored, webCam]);

  const handleShowCamera = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setLoadingCam(true);
      setCamOn(!camOn);
      setTimeout(() => {
        setLoadingCam(false);
      }, 1500);
    } catch (err) {
      if (err instanceof DOMException && err.name === "NotAllowedError") {
        // If permission is not granted, prevent form from moving to the next step
        alert(
          "Camera access was denied. Please enable it in your browser settings to proceed."
        );
      } else {
        alert("There was an error accessing the camera. Please try again.");
      }
      return;
    }
  };

  const handleTakePhoto = () => {
    setMirrored(false);
    setShouldTakePhoto(true);
  };

  const handleUsePhoto = async () => {
    if (!photoUrl) return;

    extractFieldsFromPicture(photoUrl);
  };

  const handleDiscardPhoto = () => {
    clearData();
    setPhotoUrl(null);
    setLoadingCam(true);
    setFormState((current) => {
      return { ...current, driverLicense: undefined };
    });
  };

  const handleChangeCamera = () => {
    setLoadingCam(true);
    const current = devices.indexOf(deviceId ?? "");
    const prevCam = devices[current - 1] ?? devices[devices.length - 1];

    setDeviceId(prevCam);
    setTimeout(() => {
      setLoadingCam(false);
    }, 1500);
  };

  const firstAttemptErrored = parseError.error && parseError.errorCount < 2;
  const photoSubmited = formState.driverLicense != null;
  return (
    <>
      <Grid item xs={12}>
        <Typography align="center" variant="subtitle2">
          {!photoSubmited &&
            (!parseError.error || !firstAttemptErrored) &&
            t("formB.page2.heading")}
        </Typography>
      </Grid>
      {photoUrl ? (
        <Grid container spacing={1}>
          <Grid item xs={12} height="390px" textAlign="center">
            {firstAttemptErrored ? (
              <Typography color="error" fontSize="20px" mt="-1rem" mb="0.5rem">
                {t("formB.page2.cameraError1")}
              </Typography>
            ) : (
              photoSubmited && (
                <Typography
                  color="green"
                  fontSize="20px"
                  mt="-1rem"
                  mb="0.5rem"
                >
                  {t("formB.page2.cameraSuccess")}
                </Typography>
              )
            )}
            {camOn && (
              <Box
                component="img"
                ref={webCam}
                className={styles.webcam}
                src={photoUrl}
                alt="Your Screenshot"
              />
            )}
            {firstAttemptErrored && (
              <Typography color="error">
                {t("formB.page2.cameraError2")}
              </Typography>
            )}
          </Grid>
          {!parseError.error || firstAttemptErrored ? (
            <Grid item xs={parseError.error ? 12 : 6}>
              <Button
                fullWidth
                variant="outlined"
                color={parseError.error ? "error" : "info"}
                onClick={handleDiscardPhoto}
              >
                {parseError.error
                  ? t("formB.page2.retry")
                  : t("formB.page2.tryAgain")}
              </Button>
            </Grid>
          ) : null}
          {parseError.error && parseError.errorCount > 1 && (
            <Grid item xs={parseError.error ? 12 : 6}>
              <Button
                endIcon={
                  procesingPhoto ? (
                    <CircularProgress size={20} color="warning" />
                  ) : photoSubmited ? (
                    <CheckCircleOutline />
                  ) : undefined
                }
                disabled={photoSubmited}
                fullWidth
                variant={photoSubmited ? "contained" : "outlined"}
                color={procesingPhoto ? "warning" : "success"}
                onClick={handleUsePhoto}
              >
                {photoSubmited
                  ? t("formB.page2.continue")
                  : procesingPhoto
                  ? t("formB.page2.processing")
                  : t("formB.page2.useWithErrors")}
              </Button>
            </Grid>
          )}
          <Grid item xs={6}>
            {!parseError.error && (
              <Button
                endIcon={
                  procesingPhoto ? (
                    <CircularProgress size={20} color="warning" />
                  ) : photoSubmited ? (
                    <CheckCircleOutline />
                  ) : undefined
                }
                disabled={photoSubmited}
                fullWidth
                variant={photoSubmited ? "contained" : "outlined"}
                color={procesingPhoto ? "warning" : "success"}
                onClick={() => handleUsePhoto()}
              >
                {photoSubmited
                  ? t("formB.page2.continue")
                  : procesingPhoto
                  ? t("formB.page2.processing")
                  : t("formB.page2.use")}
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item xs={12} textAlign="center">
            {camOn && (
              <Webcam
                ref={webCam}
                onCanPlay={() => setLoadingCam(false)}
                audio={false}
                videoConstraints={{
                  deviceId: deviceId,
                  aspectRatio: isPortrait ? 1 / 1.618 : 1.618,
                  facingMode: deviceId ? undefined : "environment",
                }}
                mirrored={mirrored}
                screenshotQuality={1}
                screenshotFormat="image/jpeg"
                className={styles.webcam}
                forceScreenshotSourceSize
              />
            )}
          </Grid>
          {camOn && loadingCam ? (
            <Box display="flex " width="100%" justifyContent="center">
              <CircularProgress size={80} />
            </Box>
          ) : camOn ? (
            <Grid container spacing={1}>
              <>
                <Grid item xs={6} textAlign="right">
                  <Button variant="contained" onClick={handleTakePhoto}>
                    {t("formB.page2.capture")}
                  </Button>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <IconButton
                    color="info"
                    onClick={handleChangeCamera}
                    disabled={devices.length < 2}
                  >
                    <Cameraswitch />
                  </IconButton>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <IconButton
                    color="info"
                    onClick={() => setMirrored(!mirrored)}
                  >
                    <SwapHoriz />
                  </IconButton>
                </Grid>
              </>
            </Grid>
          ) : null}
          {!formState.driverLicense && (
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" onClick={handleShowCamera}>
                {camOn ? t("formB.page2.cameraOff") : t("formB.page2.cameraOn")}
              </Button>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Page2;
