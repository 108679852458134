import { Grid, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState, useRef, useCallback } from 'react';

import { FormAProps } from '../../ts/interfaces';
import { extractAddress, googleMapsAPI, loadAsyncScript } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const Page2 = ({ formState, setFormState }: Pick<FormAProps, 'formState' | 'setFormState'>) => {
	const [sameAddr, setSameAddr] = useState(false);
	const [t] = useTranslation('global');
	const billingRef = useRef<HTMLInputElement | null>(null);
	const shippingRef = useRef<HTMLInputElement | null>(null);

	const handleChangeBilling = (autocomplete: any) => {
		const place = autocomplete.getPlace();
		const address = extractAddress(place);

		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
					shippingAddress: address,
				};
			});
		} else {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
				};
			});
		}
	};

	const handleChangeShipping = (autocomplete: any) => {
		const place = autocomplete.getPlace();
		const address = extractAddress(place);

		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					billingAddress: address,
					shippingAddress: address,
				};
			});
		} else {
			setFormState((current) => {
				return {
					...current,
					shippingAddress: address,
				};
			});
		}
	};

	const initMapScript = () => {
		if (window.google) {
			return Promise.resolve();
		}
		const src = googleMapsAPI;
		return loadAsyncScript(src);
	};

	const initAutocomplete = useCallback(() => {
		if (!billingRef.current || !shippingRef.current) return;

		const componentRestrictions = { country: 'us' };
		const types = ['address'];

		const billingAutocomplete = new window.google.maps.places.Autocomplete(billingRef.current, {
			componentRestrictions,
			types,
		});
		billingAutocomplete.setFields(['address_component', 'geometry']);
		billingAutocomplete.addListener('place_changed', () =>
			handleChangeBilling(billingAutocomplete)
		);

		const shippingAutocomplete = new window.google.maps.places.Autocomplete(shippingRef.current, {
			componentRestrictions,
			types,
		});
		shippingAutocomplete.setFields(['address_component', 'formatted_address']);
		shippingAutocomplete.addListener('place_changed', () =>
			handleChangeShipping(shippingAutocomplete)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sameAddr]);

	useEffect(() => {
		initMapScript().then(() => initAutocomplete());
	}, [initAutocomplete]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name }: { value: any; name: string } = e.target;

		if (sameAddr) {
			setFormState((current) => {
				return { ...current, billingAddress: value, shippingAddress: value };
			});
		} else {
			setFormState((current: any) => {
				return { ...current, [name]: value };
			});
		}
	};

	const handleSameAddress = () => {
		setSameAddr((prevState) => !prevState);
		if (sameAddr) {
			setFormState((current) => {
				return {
					...current,
					shippingAddress: '',
				};
			});
		} else {
			setFormState((current) => {
				if (current.billingAddress === '') return current;

				return {
					...current,
					shippingAddress: current.billingAddress,
				};
			});
		}
	};

	return (
		<>
			<Grid item xs={12}>
				<Typography marginBottom="2rem" marginLeft="0.5rem" color="grey">
					{t('formA.page2.example')}
				</Typography>
				<TextField
					fullWidth
					label={t('formA.page2.billing')}
					placeholder="123 Main St, San Francisco, CA, 94107"
					name="billingAddress"
					value={formState.billingAddress}
					onChange={handleChange}
					inputRef={billingRef}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					value={true}
					onChange={handleSameAddress}
					control={<Checkbox />}
					label={t('formA.page2.checkbox')}
					checked={sameAddr}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					fullWidth
					label={t('formA.page2.shipping')}
					placeholder="123 Main St, San Francisco, CA, 94107"
					name="shippingAddress"
					value={formState.shippingAddress}
					onChange={handleChange}
					inputRef={shippingRef}
				/>
			</Grid>
		</>
	);
};

export default Page2;
