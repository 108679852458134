import { CssBaseline, ThemeProvider, createTheme, colors } from "@mui/material";

interface Props {
  children: JSX.Element;
}

const AppTheme = ({ children }: Props) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#4F245E",
        light: "#a94bc9",
        dark: "#351840",
      },
      secondary: {
        main: colors.blueGrey[200],
        light: colors.blueGrey[50],
        dark: colors.blueGrey[700],
      },
      error: {
        main: colors.red[500],
        light: colors.red[300],
        dark: colors.red[700],
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AppTheme;
