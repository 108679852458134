import {
  Stepper as MUIStepper,
  Step,
  StepLabel,
  Box,
  Button,
} from "@mui/material";
import * as React from "react";

import { stepsDescr, stepsCount } from "./steps";
import { StepperProps } from "../../ts/interfaces";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Stepper = ({ formState, activeStep, setActiveStep }: StepperProps) => {
  const [t] = useTranslation("global");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAbleToContinue = useMemo(() => {
    if (
      activeStep === 0 &&
      (formState.dob === "" ||
        formState.ssnLast4.length < 4 ||
        formState.optAddress === "")
    ) {
      return false;
    }
    
    if (activeStep === 2) {
      return false;
    }
    return true;
  }, [formState, activeStep]);

  return (
    <Box display='flex' flexDirection='column' width='100%'>
      <MUIStepper activeStep={activeStep}>
        {stepsDescr.map((label) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label === "Confirm Your Personal Information" ? t("formA.stepper.confirmInfo") : t("formA.stepper.confirmAddress")}</StepLabel>
            </Step>
          );
        })}
      </MUIStepper>
      {activeStep <= stepsCount ? (
        <>
          <Box height='50px' display='flex' p={2}>
            {activeStep !== 0 && (
              <Button
                variant='outlined'
                color='inherit'
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                {t("formA.stepper.button1")}
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              variant='contained'
              disabled={!handleAbleToContinue}
              onClick={handleNext}
            >
              {activeStep === stepsCount - 1 || activeStep === stepsCount ? t("formA.stepper.button3") : t("formA.stepper.button2")}
            </Button>
          </Box>
        </>
      ) : (
        <Box height='50px' sx={{ flex: "1 1 auto" }} />
      )}
    </Box>
  );
};

export default Stepper;
